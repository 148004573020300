<script>
  import api from "../../api";
  import BlogSectionListCard from "./BlogSectionListCard.vue";
  export default {
    components: { BlogSectionListCard },
    data: () => {
      return {
        posts: null,
        loading: true,
        cpage: 1,
        morepage: false,
      };
    },
    props: {
      items: Array,
    },
    methods: {
      getPosts: async function (page) {
        this.loading = true;
        if (this.items) {
          this.posts = this.items;
        } else {
          this.filter = this.$route.query.filter ? this.$route.query.filter : "";
          page = page >= 1 ? page : 1;
          let response = await api.get(`${process.env.VUE_APP_API_HOST}blog/articles?page=${page}${this.filter ? `&filter=${this.filter}` : ""}`);
          if (page > 1) {
            this.posts.push(...response.data.articles);
          } else {
            this.posts = response.data.articles;
          }

          this.morepage = response.data.isMorePages;
          this.cpage = this.morepage ? this.cpage : 1;
        }
        this.loading = false;
      },
    },

    computed: {
      initData: async function () {
        await this.getPosts();
      },
    },
  };
</script>

<template>
  <div class="section-blog-posts" :class="{ loading: loading }" v-bind="initData">
    <div class="section-blog-posts-wrap">
      <div class="section-blog-posts-main main">
        <div class="section-blog-posts-main-content" v-if="posts">
          <blog-section-list-card v-for="(post, index) in posts" :key="index" :post="post"></blog-section-list-card>
        </div>
      </div>
    </div>
    <div class="load-more-block relative" :class="{ loading: loading }" v-if="morepage">
      <div class="load-more-block-content" v-if="!loading">
        <button class="click-on-bottom" @click="getPosts((cpage += 1))">Ver mas</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .section-blog-posts {
    &.loading {
      &:before {
        left: calc(50% - 95px);
      }
    }
    &-main {
      display: inline-block;
      width: 100%;
      &-content {
        padding: $mpadding/2;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
      }
    }
  }
</style>
